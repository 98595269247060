import { parseISO, format } from "date-fns";

const API_URL = process.env.WORDPRESS_API_URL;
const ARCHIVE_TAG_ID = "dGVybToyNTE0";

async function fetchAPI(query = "", { variables } = {}) {
  const headers = { "Content-Type": "application/json" };

  if (process.env.WORDPRESS_AUTH_REFRESH_TOKEN) {
    headers[
      "Authorization"
    ] = `Bearer ${process.env.WORDPRESS_AUTH_REFRESH_TOKEN}`;
  }

  // WPGraphQL Plugin must be enabled
  const res = await fetch(API_URL, {
    headers,
    method: "POST",
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  const json = await res.json();
  if (json.errors) {
    console.error(json.errors);
    throw new Error("Failed to fetch API");
  }
  return json.data;
}

export function getDateUrlSlug(node) {
  const date = parseISO(node.date);
  const year = format(date, "yyyy");
  const month = format(date, "M");
  const day = format(date, "d");
  return `${year}/${month}/${day}`;
}

export async function getAllPostsWithSlug(includeArchive = false) {
  const archiveTagWhere = `, where: { tagNotIn: "${ARCHIVE_TAG_ID}" }`;
  const data = await fetchAPI(`
    {
      posts(first: 10000 ${includeArchive ? "" : archiveTagWhere}) {
        edges {
          node {
            slug
            date
          }
        }
      }
    }
  `);
  return data?.posts;
}

export async function getAllPostsForHome(
  preview = false,
  includeArchive = false,
  count = 10
) {
  const archiveTagWhere = `, tagNotIn: "${ARCHIVE_TAG_ID}"`;
  const data = await fetchAPI(
    `
    query AllPosts($count: Int!) {
      posts(first: $count, where: { orderby: { field: DATE, order: DESC } ${
        includeArchive ? "" : archiveTagWhere
      } }) {
        edges {
          node {
            title
            excerpt
            slug
            date
            featuredImage {
              node {
                sourceUrl
              }
            }
            author {
              node {
                name
                avatar {
                  url
                }
              }
            }
            categories {
              nodes {
                name
                slug
              }
            }
          }
        }
      }
    }
  `,
    {
      variables: {
        onlyEnabled: !preview,
        preview,
        count: count,
      },
    }
  );

  return data?.posts;
}

export async function getPaginatedPostsForward(
  preview = false,
  includeArchive = false,
  first = 10,
  after = ""
) {
  const archiveTagWhere = `, tagNotIn: "${ARCHIVE_TAG_ID}"`;
  const data = await fetchAPI(
    `
    query PaginatedPosts($first: Int!, $after: String!) {
      posts(first: $first, after: $after, where: { orderby: { field: DATE, order: DESC } ${
        includeArchive ? "" : archiveTagWhere
      } }) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            title
            excerpt
            slug
            date
            featuredImage {
              node {
                sourceUrl
              }
            }
            author {
              node {
                name
                avatar {
                  url
                }
              }
            }
            categories {
              nodes {
                name
                slug
              }
            }
          }
        }
      }
    }
  `,
    {
      variables: {
        onlyEnabled: !preview,
        preview,
        first: first,
        after: after,
      },
    }
  );

  return data?.posts;
}

export async function getAllCategories() {
  const data = await fetchAPI(
    `
    query AllCategories {
      categories(first: 100) {
        nodes {
          slug
          name
        }
      }
    }
  `
  );

  return data?.categories;
}

export async function getCategoryNameFromSlug(slug) {
  const data = await fetchAPI(
    `
    query CategoryNameFromSlug($id: ID!) {
      category(id: $id, idType: SLUG) {
        name
      }
    }
  `,
    {
      variables: {
        id: slug,
      },
    }
  );

  return data?.category?.name;
}

export async function getPostsForCategory(
  categoryName,
  includeArchive = false
) {
  const archiveTagWhere = `, tagNotIn: "${ARCHIVE_TAG_ID}"`;
  const data = await fetchAPI(
    `
    query PostsForCategory($categoryName: String!) {
      posts(first: 10000, where: {categoryName: $categoryName ${
        includeArchive ? "" : archiveTagWhere
      } }) {
        edges {
          node {
            title
            excerpt
            slug
            date
            featuredImage {
              node {
                sourceUrl
              }
            }
            author {
              node {
                name
                avatar {
                  url
                }
              }
            }
            categories {
              nodes {
                name
                slug
              }
            }
          }
        }
      }
    }
  `,
    {
      variables: {
        categoryName: categoryName,
      },
    }
  );

  return data?.posts;
}

export async function getPostsForSearch(searchKeyword) {
  const data = await fetchAPI(
    `
    query PostsForCategory($searchKeyword: String!) {
      posts(first: 10000, where: {search: $searchKeyword}) {
        edges {
          node {
            title
            excerpt
            slug
            date
            featuredImage {
              node {
                sourceUrl
              }
            }
            author {
              node {
                name
                avatar {
                  url
                }
              }
            }
            categories {
              nodes {
                name
                slug
              }
            }
          }
        }
      }
    }
  `,
    {
      variables: {
        searchKeyword: searchKeyword,
      },
    }
  );

  return data?.posts;
}

export async function getPost(slug) {
  const data = await fetchAPI(
    `
    fragment AuthorFields on User {
      name
      firstName
      lastName
      avatar {
        url
      }
    }
    fragment PostFields on Post {
      title
      excerpt
      slug
      date
      featuredImage {
        node {
          sourceUrl
        }
      }
      author {
        node {
          ...AuthorFields
        }
      }
      categories {
        edges {
          node {
            name
            slug
          }
        }
      }
      tags {
        edges {
          node {
            name
          }
        }
      }
    }
    query PostBySlug($id: ID!, $idType: PostIdType!) {
      post(id: $id, idType: $idType) {
        ...PostFields
        content
      }
    }
  `,
    {
      variables: {
        id: slug,
        idType: "SLUG",
      },
    }
  );

  // Filter out the main post
  // data.posts.edges = data.posts.edges.filter(({ node }) => node.slug !== slug);
  // If there are still 3 posts, remove the last one
  // if (data.posts.edges.length > 2) data.posts.edges.pop();

  return data;
}
