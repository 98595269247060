import { GetStaticProps } from "next";
import Head from "next/head";
import Link from "next/link";
import { BlogList } from "@/components/BlogList";
import { getAllPostsForHome } from "@/lib/posts";
import { Hero } from "@/components/Hero";
import { Layout } from "@/components/Layout";
import { SectionContainer } from "@/components/SectionContainer";

export default function Home({ allPosts: { edges } }) {
  const pageTitle = "Govern For California";
  return (
    <>
      <Head>
        <title>{`${pageTitle}`}</title>
        <meta
          name="description"
          content="Govern For California is a special interest for the general interest and a supporter of like-minded organizations"
        />
      </Head>
      <Layout>
        <Hero />
        <SectionContainer bgColor={"bg-white"} className="relative">
          <div className="pb-6 pt-4">
            <Link
              href="/news"
              className="text-3xl font-bold tracking-tight text-gray-900"
            >
              Recent News
            </Link>
          </div>
          <BlogList posts={edges} />
          <div className="mt-6">
            <Link
              href="/news"
              className="text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              View all
            </Link>
          </div>
        </SectionContainer>
      </Layout>
    </>
  );
}

export const getStaticProps: GetStaticProps = async (context) => {
  const allPosts = await getAllPostsForHome(false, false, 3);

  return {
    props: { allPosts },
    revalidate: 60,
  };
};
