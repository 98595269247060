import { CallToAction } from "@/components/CallToAction";
import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";

export function Layout({ hideCTA = false, mobileTitle = "", children }) {
  return (
    <>
      <Header mobileTitle={mobileTitle} />
      <main>
        <div className="pt-12 pb-6">{children}</div>
        <CallToAction className={hideCTA ? "hidden" : ""} />
      </main>
      <Footer />
    </>
  );
}
