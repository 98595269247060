export function SectionContainer({
  bgColor = null,
  pt = null,
  className = "",
  children,
  ...props
}) {
  return (
    <section
      className={`pb-6 ${
        bgColor ? bgColor : "bg-gray-50"
      } sm:text-center lg:text-left ${className}`}
      {...props}
    >
      <div className={`mx-auto max-w-7xl px-4 sm:px-6 lg:px-8`}>{children}</div>
    </section>
  );
}
